<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2"></font></div></td>
          </tr>
          <tr>
            <td><div align="left">
              <p align="left">Textures or patterns of the contaminants play an important role for photorealistically reproducing the  effects. We model the patterns as 2D optical thickness texture, τ(x,y).   To measure τ(x,y), we use the shadow map generated by  attenuation from the contaminant layer.  The following image shows our setup. The projector  illuminates a thin glass slab with contaminants on the far side.  Behind it is a Lambertian board, and the camera is on the side.  The camera is radiometrically calibrated beforehand.   The intensity of each point in the shadow map comes from two parts: the  attenuated transmitted light from this point, and the  scattering component from neighboring points.  Since the albedo of the contaminant layer is assumed to be small  (otherwise it would generate multiple scattering) and it is mostly  forward scattering, there will be much less contribution from  neighboring points due to scattering. Thus the attenuated light is much  stronger than the scattered light and it is the intensity of the shadow  map. For some samples we can indeed  observe the effect of scattering from neighboring points in the  shadow map, for which the shadow map usually is blurry, especially  when the board is far from the glass. In those cases, we let the  projector shine light through the glass and put the camera on the  other side. This setup allows us to measure τ(x,y) by  scattering and  the result is then scaled by the initial measurement. </p>
              
                <div align="left"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/setup.jpg">
                      <br>
                  </div>
                <h3 align="left">Copyright</h3>
                <div align="left">The database is the property of Columbia University. This data can only be used for research or  academic purposes. Any commercial use of the data whatsoever or incorporation of the data into a  larger database intended for public distribution must be done with the explicit written consent of  CAVE administrators. <br>
                  </div>
                <h3 align="left">Download</h3>
              <p align="left">The database contains 36 samples, including various kinds of contaminations, such as dust, dirt,  fingerprints, lipids, soap water deposit, oily smudges, clay, and so on. The image intensity  is propotional to the optical thickness. We assume there is only one kind of contamination for each  sample. The images are available in two formats: EXR and PNG.  EXR is a format for high dynamic range images. Here we assume the contamination layer is very thin,   so the image dynamic rang is in fact not very high. But still, floating point can give us more  accurate resolution. For more information about EXR, visit <a href="http://www.openexr.com">OpenEXR</a> please. </p>
              <p align="left">Click each of the sample in the following table to download. Also, the 36 samples are zipped and  can be downloaded together in <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/texture.exr.zip">EXR (163MB)</a> and <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/texture.png.zip">PNG (17MB)</a>. We provide some Matlab code for reading and writing EXR files, as well as a simple synthesis tool which allows the use to interactively generate new large textures from the acquired samples <router-link to="/repository/Contaminants/source">here</router-link>. </p>
              <p align="left"> </p>
              
                <div align="left">
				<center>
                  <table border="2">
                    <tbody>
                      <tr>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-001.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-001.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-001.exr">dirt-001.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-002.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-002.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-002.exr">dirt-002.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-003.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-003.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-003.exr">dirt-003.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-004.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-004.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dirt-004.exr">dirt-004.exr</a> </td>
                      </tr>
                      <tr>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dust-001.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dust-001.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dust-001.exr">dust-001.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dust-002.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dust-002.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dust-002.exr">dust-002.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dust-003.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dust-003.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/dust-003.exr">dust-003.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-001.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-001.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-001.exr">fingerprint-001.exr</a> </td>
                      </tr>
                      <tr>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-002.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-002.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-002.exr">fingerprint-002.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-004.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-004.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-004.exr">fingerprint-004.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-005.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-005.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-005.exr">fingerprint-005.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-006.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-006.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-006.exr">fingerprint-006.exr</a> </td>
                      </tr>
                      <tr>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-007.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-007.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/fingerprint-007.exr">fingerprint-007.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/powder-001.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/powder-001.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/powder-001.exr">powder-001.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-001.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-001.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-001.exr">salt-001.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-003.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-003.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-003.exr">salt-003.exr</a> </td>
                      </tr>
                      <tr>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-004.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-004.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-004.exr">salt-004.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-007.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-007.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-007.exr">salt-007.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-008.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-008.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/salt-008.exr">salt-008.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/smudges-001.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/smudges-001.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/smudges-001.exr">smudges-001.exr</a> </td>
                      </tr>
                      <tr>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/smudges-002.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/smudges-002.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/smudges-002.exr">smudges-002.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/smudges-003.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/smudges-003.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/smudges-003.exr">smudges-003.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-003.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-003.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-003.exr">soap-003.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-005.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-005.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-005.exr">soap-005.exr</a> </td>
                      </tr>
                      <tr>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-006.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-006.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-006.exr">soap-006.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-007.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-007.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-007.exr">soap-007.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-008.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-008.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-008.exr">soap-008.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-009.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-009.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-009.exr">soap-009.exr</a> </td>
                      </tr>
                      <tr>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-010.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-010.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-010.exr">soap-010.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-011.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-011.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-011.exr">soap-011.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-012.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-012.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-012.exr">soap-012.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-013.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-013.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/soap-013.exr">soap-013.exr</a> </td>
                      </tr>
                      <tr>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-001.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-001.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-001.exr">waterstripes-001.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-002.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-002.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-002.exr">waterstripes-002.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-003.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-003.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-003.exr">waterstripes-003.exr</a> </td>
                        <td align="center"><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-004.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-004.thumb.png" height="120"></a><br>
                          <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/texture/waterstripes-004.exr">waterstripes-004.exr</a></td>
                      </tr>
                      </tbody>
                    </table>
					</center>
                </div>
            </div></td>
          </tr>
          <tr>
            <td><hr>
              <router-link to="/repository/Contaminants">Database Home</router-link><br>
Contact:&nbsp;<a href="mailto:jwgu@cs.columbia.edu">jwgu@cs.columbia.edu</a><br>
Last modified: 06/02/2007</td>
          </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>